<template>
  <div class="landing-inner-banner">
    <h1 class="text-center mt-3 mb-0 mx-1" style="font-weight: bold;">
      {{$t("hotel-page.hotel-breadcrumb-dest-third-clone",
      {destinationName: chainName})}}
      </h1>
    <div class="container">
      <div class="row">
        <LandingChainBannerSlide :chainName="chainName"/>
        <LandingChainContact :chainName="chainName"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LandingBanner',
  components: {
    LandingChainBannerSlide: () => import('@/components/landing/LandingChainBannerSlide'),
    LandingChainContact: () => import('@/components/landing/LandingChainContact'),
  },
  props: {
    chainName: String,
  },
};
</script>

<style scoped>
  .landing-inner-banner {
    width: 100%;
    background: url('/assets/img/banner_landing.jpg');
    background-size: 100% 100%;
    padding-top: 30px;
  }
  .container{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  @media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
  }
</style>
